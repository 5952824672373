import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LookupPropertyCodeService {

  constructor(private http: HttpClient) { }


  lookupPropertyCode(request): Observable<any> {
    return this.http.post('/protected/lookupPropertyCode', request);
  }

  lookupPropertyCodeWithPrefix(request): Observable<any> {
    return this.http.post('/protected/lookupPropertyCodeWithPrefix', request);
  }
}
